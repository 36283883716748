import { images } from '../assets/images';

export const sidebarMenu = [
    {
        path: '/visual',
        name: 'My Body Map',
        icon: images.upload,
    },
    {
        path: '/symptom',
        name: 'My Bio Diary',
        icon: images.share,
    },
    {
        path: '/dashboard',
        name: 'My Bio View',
        icon: images.overview,
    },
    {
        path: '/pricing',
        name: 'Pricing plans',
        icon: images.share,
    }
];

export const sideBottomMenu = [
    // {
    //     path: '',
    //     name: 'Settings',
    //     icon: images.setting,
    // },
    {
        path: '',
        name: 'Logout',
        icon: images.logout,
    }
];