export const images = {
  bodyMapper_logo: require("./icons/bodyMappr.png"),
  bodyMapper_green_logo: require("./icons/bodymappr Final-green.png"),
  image1: require("../assets/images/trafalgar-header illustration 2.png"),
  image2: require("../assets/images/bgVector_r.png"),
  icon1: require("../assets/icons/005-phone-1.png"),
  icon2: require("../assets/icons/Heart Rate.png"),
  icon3: require("../assets/icons/Frame.png"),
  icon4: require("../assets/icons/007-cloud.png"),
  iconBgImg: require("../assets/images/Vector.png"),
  expert1: require("../assets/images/experts (2).png"),
  expert2: require("../assets/images/experts (1).png"),

  // profile images for dashboard screen
  maleProfile: require("../assets/icons/male.jpg"),
  femaleProfile: require("../assets/icons/female.jpg"),

  // female male skeleton for home screen
  femaleAnatomy: require("../assets/images/Body-mappr.png"),
  maleAnatomy: require("../assets/images/Body-mappr.1.png"),


  bgVector: require("../assets/images/bgVector.png"),
  bgRectangle: require("../assets/images/rectangle.jpg"),
  bgReactangle_p: require("../assets/images/rectangle_p.jpg"),
  bgReactangle_h: require("../assets/images/rectangle_h.jpg"),
  bgReactangle_g: require("../assets/images/rectangle_g.jpg"),
  girlImg1: require("../assets/images/Rectangle 2351.png"),
  girlImg2: require("../assets/images/Rectangle 2352.png"),
  fitIcon1: require("../assets/icons/fluent-mdl2_commitments.png"),
  fitIcon2: require("../assets/icons/grommet-icons_user-expert.png"),
  fitIcon3: require("../assets/icons/icon-park-outline_customer.png"),
  fitIcon4:require("../assets/icons/ai.png"),
  appleIcon: require("../assets/icons/apple-logo.png"),
  fbIcon: require("../assets/icons/facebook.png"),
  googleIcon: require("../assets/icons/search.png"),
  paypalIcon:require("../assets/icons/paypal.png"),
  bars: require("../assets/icons/bar.png"),
  overview: require("../assets/icons/Group(1).png"),
  upload: require("../assets/icons/Group(2).png"),
  share: require("../assets/icons/Group(3).png"),
  setting: require("../assets/icons/Group(4).png"),
  logout: require("../assets/icons/Group(5).png"),
  profilePic: require("../assets/images/Rectangle 1675.png"),
  user1: require("../assets/images/Ellipse 47.png"),
  user2: require("../assets/images/Ellipse 50.png"),
  user3: require("../assets/images/Ellipse 52.png"),

  // male body images
  group1: require("./images/MaleBody/hb1.png"),
  hb1: require("./images/MaleBody/grp1.png"),
  hb2: require("./images/MaleBody/grp6.png"),
  hb3: require("./images/MaleBody/grp5.png"),
  hb4: require("./images/MaleBody/grp4.png"),
  hb5: require("./images/MaleBody/grp3.png"),
  hb6: require("./images/MaleBody/grp2.png"),

  // female body images
  group2: require("./images/FemaleBody/hb2.png"),
  fhb1: require("./images/FemaleBody/sgrp1.png"),
  fhb2: require("./images/FemaleBody/sgrp2.png"),
  fhb3: require("./images/FemaleBody/sgrp3.png"),
  fhb4: require("./images/FemaleBody/sgrp4.png"),
  fhb5: require("./images/FemaleBody/sgrp5.png"),
  fhb6: require("./images/FemaleBody/sgrp6.png"),
  fhb7: require("./images/FemaleBody/sgrp7.png"),

  // Male tabs images for switcher
  mTab1: require("../assets/images/male-body (1).png"),
  mTab2: require("../assets/images/male-body (2).png"),
  mTab3: require("../assets/images/male-body (3).png"),
  mTab4: require("../assets/images/male-body (4).png"),
  mTab5: require("../assets/images/male-body (5).png"),
  mTab6: require("../assets/images/male-body (6).png"),

  // female tabs images for switcher
  fmTab1: require("../assets/images/Bodymappr-women-1.png"),
  fmTab2: require("../assets/images/Bodymappr-women-2.png"),
  fmTab3: require("../assets/images/Bodymappr-women-3.png"),
  fmTab4: require("../assets/images/Bodymappr-women-4.png"),
  fmTab5: require("../assets/images/Bodymappr-women-5.png"),
  fmTab6: require("../assets/images/Bodymappr-women-6.png"),
  fmTab7: require("../assets/images/Bodymappr-women.png"),



  tab1: require("../assets/images/Ellipse 1.png"),
  tab2: require("../assets/images/Ellipse 2.png"),
  tab3: require("../assets/images/Ellipse 3.png"),
  tab4: require("../assets/images/Ellipse 4.png"),
  tab5: require("../assets/images/Ellipse 5.png"),
  tab6: require("../assets/images/Ellipse 6.png"),
  elipse1: require("../assets/images/Ellipse 9.png"),
  timelineIcon: require("../assets/icons/tlicon.png"),

  // logout
  logoutIcon: require("../assets/icons/logout.png"),
};
