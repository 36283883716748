import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  Typography,
  Menu,
} from "@mui/material";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/reduxSlice/authSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Avatar from "../../components/profileAvatar/Avatar";
import config from "../../utils/config";

const AuthButton = ({ handleNavigation }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const settings = ["Profile", "Dashboard", "Logout"];
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    event.stopPropagation();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = async (setting) => {
    handleCloseUserMenu();
    if (setting === "Logout") {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        dispatch(logout());
        navigate("/login");
        message.success("Logged out successfully.");
      } catch (error) {
        message.error("Logout failed. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      handleNavigation(`/${setting.toLowerCase()}`);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (userId) {
          const response = await axios.get(
            `${config.apiBaseUrl}/auth/getUser?id=${userId}`
          );
          setUser(response.data.user);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoggedIn(false);
      }
    };

    fetchUserData();
  }, []);

  const renderBMUserPart = () => (
    <Box className="bm_user">
      <Tooltip title="Open settings">
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0 }}
          aria-label="User Settings"
        >
          <Avatar
            name={
              user && user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : ""
            }
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={() => handleMenuItemClick(setting)}>
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );

  const publicRoutes = [
    "/",
    "/about",
    "/service",
    "/contact",
    "/pricing",
    "/pricePackage",
  ];
  const dashboardRoutes = [
    "/dashboard",
    "/visual",
    "/symptom",
    "/profile",
    "/healthHistory",
  ];

  const shouldRenderBMUserPart =
    isLoggedIn &&
    (publicRoutes.includes(location.pathname) ||
      dashboardRoutes.includes(location.pathname));
  const isLoginRoute = location.pathname === "/login";
  const isSignupRoute = location.pathname === "/signup";

  if (shouldRenderBMUserPart) {
    return renderBMUserPart();
  } else if (isLoginRoute) {
    return (
      <Button
        component={Link}
        to="/signup"
        className="header_signin_button"
        sx={{
          "@media (max-width: 902px)": {
            fontWeight: "600",
            color: "white",
            margin: "1rem",
          },
        }}
      >
        Signup
      </Button>
    );
  } else if (isSignupRoute) {
    return (
      <Button
        component={Link}
        to="/login"
        className="header_signin_button"
        sx={{
          "@media (max-width: 902px)": {
            fontWeight: "600",
            color: "white",
            margin: "1rem",
          },
        }}
      >
        Login
      </Button>
    );
  } else {
    return (
      <Button
        component={Link}
        to="/login"
        className="header_signin_button"
        sx={{
          "@media (max-width: 902px)": {
            fontWeight: "600",
            color: "white",
            margin: "1rem",
          },
        }}
      >
        Login
      </Button>
    );
  }
};

export default AuthButton;
