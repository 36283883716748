import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
// import { LuPhone } from "react-icons/lu";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";

export const socialIcon = [
  {
    id: 1,
    icon: <FacebookRoundedIcon />,
  },
  {
    id: 2,
    icon: <InstagramIcon />,
  },
  {
    id: 3,
    icon: <TwitterIcon />,
  },
];

export const footerList = [
  {
    id: 1,
    title: "Company Info",
    list: ["About Us", "Carrier", "We are hiring", "Blog"],
  },
  {
    id: 2,
    title: "Legal",
    list: ["About Us", "Carrier", "We are hiring", "Blog"],
  },
  {
    id: 3,
    title: "Features",
    list: [
      "Business Marketing",
      "User Analytic",
      "Live Chat",
      "Unlimited Support",
    ],
  },
  {
    id: 4,
    title: "Resources",
    list: ["IOS & Android", "Watch a Demo", "Customers", "API"],
  },
  {
    id: 5,
    title: "Get In Touch",
    list: [
      // "(480) 555-0103",
      "Bodymappr, 159 Porchester Terrace North, London, United Kingdom",
      "Info@bodymappr.com",
    ],
    icons: [
      // <LuPhone
      //   style={{ fontSize: "1.5rem", color: "#00672F", marginRight: ".5rem" }}
      // />,
      <IoLocationSharp className="icon-location" />,
      <IoIosMail
        style={{ fontSize: "1.5rem", color: "#00672F", marginRight: ".5rem" }}
      />,
    ],
  },
];
