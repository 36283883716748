import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import { images } from "../../assets/images";
import { HeaderContainer } from '../../services/header';
import useHeader from '../../constants/headerMenuList';
import MobileSideBar from './MobileSideBar';
import { Link, useLocation } from 'react-router-dom';
import AuthButton from './authButton';
import { useSelector } from 'react-redux';
import { message } from 'antd';

export default function Header() {
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('902'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { menuItems, handleNavigation } = useHeader();
   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const toggleDrawer = (open) => {
    if (isDrawerOpen !== open) {
      setIsDrawerOpen(open);
    }
  };

  const handleMenuItemClick = (path, id) => {
  if (path === "/pricing" && !isLoggedIn) {
    message.warning("Please log in first to access Pricing");
  } else {
    handleNavigation(path);
    toggleDrawer(false);
  }
  };

  return (
    <HeaderContainer className="header">
      <AppBar className='header_appbar' position="static">
        <Toolbar className='header_toolbar'>
          <Box className="header_logo">
            <Link to={"/"}><img src={images.bodyMapper_logo} alt="BodyMappr" /></Link>
          </Box>
          <Box className="header_menu" >
            {menuItems.map((item) => (
              <Typography
                className={`header_menu_item ${item.path === location.pathname ? 'active' : ''}`}
                key={item.id}
                onClick={() => handleMenuItemClick(item.path, item.id)}
              >
                {item.label}
              </Typography>
            ))}
          </Box>
          <AuthButton handleNavigation={handleNavigation} />
          {isSmallScreen && (
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              className='header_menu_button'
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon className='menu_icon' />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <MobileSideBar toggleDrawer={toggleDrawer} />
      </Drawer>
    </HeaderContainer>
  );
}
