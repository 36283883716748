import styled from "styled-components";

export const FooterContainer = styled.div`
  .footer_top {
    background-color: ${({ theme }) => theme.bgLightColor};
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-evenly;
    padding: 2rem 2rem;

    .footer_logo_img {
      width: 150px;
      height: 120px;
      .footer_logo {
        width: 100%;
        height: 100%;
      }
    }

    .footer_list {
      display: flex;
      .footer_list_box {
        flex: 1;

        .list_title {
          color: ${({ theme }) => theme.textColor};
          font-family: ${({ theme }) => theme.fontManrope};
          font-size: 1rem;
          font-weight: 600;
          margin: 0.5rem 0;
        }
        .list {
          color: ${({ theme }) => theme.tertiaryColor};
          font-family: ${({ theme }) => theme.fontManrope};
          font-weight: 600;
          padding: 0.2rem 0;
          font-size: 0.9rem;
        }
      }
      /* Add this to your CSS or Tailwind file */
      .icon-location {
        font-size: 2rem;
        color: #00672f;
        margin-right: 0.5rem;
      }

      @media (min-width: 900px) {
        .icon-location {
          font-size: 4rem;
        }
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      .footer_logo_img {
        padding: 2rem 0;
      }
    }

    @media screen and (max-width: 800px) {
      .footer_list {
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2rem;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 2rem 1rem;
      .footer_list {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
  .footer_bottom {
    padding: 1rem 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    background-color: ${({ theme }) => theme.primaryColor};
    .reserved_title {
      color: ${({ theme }) => theme.whiteColor};
      font-family: ${({ theme }) => theme.fontCormorant};
    }
    .social_icon {
      display: flex;
      align-items: center;
      gap: 1rem;
      .icon {
        color: ${({ theme }) => theme.whiteColor};
      }
    }
    @media screen and (max-width: 900px) {
      padding: 1rem 1rem;
      text-align: center;
      flex-direction: column-reverse;
    }
  }
`;
