import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import commentReducer from "./commentSlice";
import healthHistoryReducer from "./healthHistorySlice";
import modalReducer from "../reduxSlice/modalSlice";
import hoverSlice from "../reduxSlice/hoverSlice";
import imageReducer from "./imageSlice";
import pinReducer from "./pinSlice";
import selectedRowIdSlice from "./historyVisalSlice/visalListSlice";
import diaryReducer from "./diarySlice";
import editReducer from './editSlice';
import queryReducer from "./querySlice";
import  pricePackageReducer from './pricePackageSlice';
import subscriptionReducer from './subscriptionSlice';

const appReducer = combineReducers({
  modal: modalReducer,
  auth: authReducer,
  comments: commentReducer,
  healthHistory: healthHistoryReducer,
  hover: hoverSlice,
  image: imageReducer,
  pin: pinReducer,
  selectedRowId: selectedRowIdSlice,
  diary: diaryReducer,
  edit: editReducer,
  query: queryReducer,
  pricePackage: pricePackageReducer,
  subscription: subscriptionReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = undefined; // Reset the state to undefined to initialize all reducers
  }
  return appReducer(state, action);
};

export default rootReducer;
