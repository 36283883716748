import React from "react";
import { Typography, Button } from "antd";
import { VscDebugDisconnect } from "react-icons/vsc";

const ErrorScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography style={{ fontSize: "5rem", fontWeight: "bold" }}>
        404
      </Typography>
      <Typography style={{ fontSize: "2rem", fontWeight: "bold" }}>
        <VscDebugDisconnect style={{color:"#00672F"}}/>
        Page Not Found
      </Typography>
      <Button
        style={{
          backgroundColor: "#00672F",
          color: "white",
          marginTop: "1rem",
        }}
      >
        <a style={{ color: "white", textDecoration: "none" }} href="/">
          Go Home
        </a>
      </Button>
    </div>
  );
};

export default ErrorScreen;
