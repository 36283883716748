import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/Router";
import { ThemeProvider } from "styled-components";
import { global } from "./style/global";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={global}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
