import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { store, persistedStore } from "./redux/store/store";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const stripePromise = loadStripe(
  "pk_test_51HTrhzFb0h2W9ZP9gKRsp8lzjM5cNtbRIRj29xChC4n13TbqZwNIZ1VscgZ3ySOWmFJDp81PniutGkRmWBkCL6AW00Z2SVL6P8"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <GoogleOAuthProvider clientId={clientId}>
        <Elements stripe={stripePromise}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Elements>
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
