import PropTypes from "prop-types";
import { Button, Modal, message, Typography } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/reduxSlice/authSlice";
import { images } from "../../assets/images";

const Logout = ({ open, handleOk, handleCancel }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch(logout());
      handleOk();
      message.success("Logged out successfully.");
    } catch (error) {
      message.error("Logout failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="custom"
      open={open}
      onCancel={handleCancel}
      mask={true}
      maskStyle={{
        backdropFilter: "blur(8px)",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      footer={[
        <div
          style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
          key="footer-buttons"
        >
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              backgroundColor: "white",
              color: "#00672F",
            }}
          >
            Cancel
          </Button>
          <Button
            key="ok"
            type="primary"
            onClick={handleConfirm}
            loading={loading}
            style={{
              backgroundColor: "#00672F",
              color: "white",
            }}
          >
            Logout
          </Button>
        </div>,
      ]}
    >
      <div className="flex flex-col justify-center items-center gap-5">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: "50px", height: "50px" }}
            src={images.logoutIcon}
            alt="logout"
          />
        </div>
        <Typography
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
            textAlign: "center",
            padding: "1rem",
          }}
        >
          Are you sure you want to logout?
        </Typography>
      </div>
    </Modal>
  );
};

Logout.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default Logout;
